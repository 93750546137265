<template>
        <!-- Activity -->
        <b-card no-body class="text-center">
            <b-row class="text-left mx-0">
                <b-col
                    cols="12"
                    class="border-top border-right d-flex align-items-between flex-column py-1"
                >
                  <a @click="navigateTo('Activitys')">
                      <h3 class="font-weight-bolder text-muted mb-0">
                          Aktivitas
                      </h3> 
                  </a>                  
                </b-col> 

                <b-col
                    cols="12"
                    class="border-top d-flex align-items-between flex-column py-1"
                >
                <a @click="navigateTo('Transfer')">
                    <h3 class="font-weight-bolder text-muted mb-0">
                        Distribusi
                    </h3> 
                </a> 
                </b-col>

                <b-col
                    cols="12"
                    class="border-top d-flex align-items-between flex-column py-1"
                >
                <a @click="navigateTo('Camera')">
                    <h3 class="font-weight-bolder text-muted mb-0">
                        <!-- Take Images -->Ambil Gambar
                    </h3> 
                </a> 
                </b-col>
            </b-row>
        </b-card>
        <!-- Activity -->
  </template>
   
  <script>
  import {
    BButton, BForm, BFormInput, BFormGroup, BCard, BCardHeader, BCardBody, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox, BAvatar, BRow, BCol
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  
  export default {
    components: {
      // BSV
      BButton,
      BForm,
      BFormInput,
      BFormGroup,
      BCard,
      BCardTitle,
      BLink,
      BCardText,
      BInputGroup,
      BInputGroupAppend,
      BFormCheckbox,
      BAvatar,
      BRow, 
      BCol,
      BCardHeader, BCardBody
    },
    directives: {
        Ripple,
    },
    data() {
      return {
        
      }
    },
    computed: {
    },
    methods: {
      navigateTo(page) {
        this.$emit('actionPage', page);
      },
    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
  